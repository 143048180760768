import React, { useEffect, useState, useCallback } from 'react';
import {
    Col,
    Form,
    Row,
    Card,
    FormGroup,
    Spinner,
    Container,
    Modal,
    Alert,
    Button,
    ButtonGroup,
} from 'react-bootstrap';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { DateTimePicker } from 'react-tempusdominus-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import {
    useDispatchState,
    useYardsState,
    useVehicleSegmentState,
    useAccountState,
    getTripSettings,
} from '../../../../Context';
import { getYards } from '../../../../Context/actions/yardActions';
import { getDispatchDetails, updateDispatchDetails, printDispatch } from '../../../../Context/actions/dispatchActions';
import { getVehicleSegments } from '../../../../Context/actions/vehicleSegmentActions';
import SimpleTable from '../../../../Components/SimpleTable';
import { formatCurrency } from '../../../../utils/helper';
import './index.scss';
import DetailsPageLayout from '../../../../Components/DetailsPageLayout/DetailsPageLayout';
import Dialog from '../../../../Components/Dialog/index';
import CustomAccordion from '../../../../Components/CustomAccordion';
import { DefaultEditor } from 'react-simple-wysiwyg';
import ListingTable from '../../../../Components/ListingTable';
import { updateDispatchDetailsService } from '../../../../services/dispatchService';

const DispatchDetails = (props) => {
    const dispatchId = props && props.match.params.id;
    const [collapseItem, handleCollapse] = useState({ 1: false });
    const {
        dispatchDispatch,
        dispatch: { errorMessage, dispatchDetails },
    } = useDispatchState();
    const {
        vehicleSegmentDispatch,
        vehicleSegment: { segments },
    } = useVehicleSegmentState();
    const {
        AccountsDispatch,
        tripSettings: { tripSettings },
    } = useAccountState();
    const { yardsDispatch } = useYardsState();
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [dispatchConfirmData, setDispatchConfirmData] = useState({
        showModal: false,
        showAcceptModel: false,
    });
    const [initialPageLoad, setInitialPageLoad] = useState(true);
    const { reset, register, control } = useForm({
        defaultValues: {
            vehicleType: '',
            vehicleId: '',
            vehiclePriceAtBooking: '',
            vehiclePrice: '',
            generalLedgerCode: '',
            driverFullName: '',
            driverStartTime: '',
            driverEndTime: '',
        },
    });
    const [loading, setLoading] = useState(false);
    let headingBackButtonObj = {
        title: ' BACK TO ASSIGNMENTS',
        url: '/driver/assignments',
    };

    useEffect(() => {
        (async function () {
            setLoading(true);
            await getTripSettings(AccountsDispatch);
            await getYards(yardsDispatch, '');
            if (dispatchId) {
                let details = await getDispatchDetails(dispatchDispatch, dispatchId);
                await getVehicleSegments(vehicleSegmentDispatch, details.vehicleAssignmentID);
                if (details.driverStatusID === '' || Number(details.driverStatusID) === 3) {
                    let newDriverStatusID = '';
                    if (details.driverStatusID === '') newDriverStatusID = 1;
                    if (Number(details.driverStatusID) === 3) newDriverStatusID = 4;
                    await updateDispatchDetails(dispatchDispatch, dispatchId, {
                        driverStatusID: newDriverStatusID,
                    });
                    details = await getDispatchDetails(dispatchDispatch, dispatchId);
                }
                reset({
                    ...details,
                    driverStartTime: details.driverStartTime ? details.driverStartTime : null,
                    driverEndTime: details.driverEndTime ? details.driverEndTime : null,
                    vehiclePrice: `$${formatCurrency(details.vehiclePrice ? details.vehiclePrice : '0')}`,
                    vehiclePriceAtBooking: `$${formatCurrency(
                        details.vehiclePriceAtBooking ? details.vehiclePriceAtBooking : '0'
                    )}`,
                });
                if (initialPageLoad) {
                    await updateDispatchDetailsService(dispatchId, {
                        driverViewedAt: moment(),
                    });
                    setInitialPageLoad(false);
                }
            }
            setLoading(false);
        })();
    }, [dispatchId, dispatchDispatch, reset, yardsDispatch, vehicleSegmentDispatch, AccountsDispatch]);

    const handleItemCollapse = (e) => {
        const { id } = e.target;
        handleCollapse({ ...collapseItem, [id]: !collapseItem[id] });
    };

    let errorContent = '';
    if (errorMessage || !dispatchId) {
        errorContent = (
            <Col xl={12}>
                <Alert variant="danger">Dispatch Details not found</Alert>
            </Col>
        );
    }

    const handlePrint = async () => {
        try {
            setLoading(true);
            const pdfData = await printDispatch(dispatchDispatch, dispatchId);
            setLoading(false);
            let blob = new Blob([pdfData], { type: 'application/pdf' });
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'Dispatch.pdf';
            link.click();
        } catch (e) {
            setLoading(false);
            console.log(e);
        }
    };

    const refreshDetails = async () => {
        let details = await getDispatchDetails(dispatchDispatch, dispatchId);
        reset({
            ...details,
            driverStartTime: details.driverStartTime ? details.driverStartTime : null,
            driverEndTime: details.driverEndTime ? details.driverEndTime : null,
            vehiclePrice: `$${formatCurrency(details.vehiclePrice ? details.vehiclePrice : '0')}`,
            vehiclePriceAtBooking: `$${formatCurrency(
                details.vehiclePriceAtBooking ? details.vehiclePriceAtBooking : '0'
            )}`,
        });
    };

    const updateDriverConfirmed = async (confirmed) => {
        setDispatchConfirmData({
            showModal: false,
            showAcceptModel: false,
        });
        let updateDispatchData = {};
        if (confirmed === 0) {
            // declined
            updateDispatchData = {
                driverDeclinedAt: new Date(),
            };
        } else {
            // accepted
            updateDispatchData = {
                driverAcceptedAt: new Date(),
            };
        }
        setLoading(true);
        await updateDispatchDetails(dispatchDispatch, dispatchId, updateDispatchData);
        await refreshDetails();
        setLoading(false);
    };

    const checkTime = useCallback(() => {
        if (dispatchDetails) {
            if (dispatchDetails.driverStartTime) {
                let difference = moment(dispatchDetails.driverStartTime).diff(moment(), 'minutes');
                if (!dispatchDetails.checkInButtonDisableMin) {
                    setIsButtonDisabled(false);
                } else if (difference < Number(dispatchDetails.checkInButtonDisableMin)) {
                    setIsButtonDisabled(false);
                } else {
                    setIsButtonDisabled(true);
                }
            } else {
                setIsButtonDisabled(true);
            }
        }
    }, [dispatchDetails]);

    useEffect(() => {
        checkTime();
    }, [checkTime, dispatchDetails]);

    useEffect(() => {
        let interval = setInterval(checkTime, 60000);
        return () => clearInterval(interval);
    }, [checkTime]);

    const handleCheck = async (item, type) => {
        setLoading(true);
        let obj = {};
        if (type === 'checkout') {
            obj = {
                driverCheckOut: new Date(),
                driverPortalCheckOutDriverID: item.relatedDriver,
            };
        } else {
            obj = {
                driverCheckIn: new Date(),
                driverPortalCheckInDriverID: item.relatedDriver,
            };
        }
        await updateDispatchDetails(dispatchDispatch, item.id, obj);
        await refreshDetails();
        setLoading(false);
    };

    const dispatchConfirmActions = (
        <ButtonGroup className="d-block float-right">
            <Button
                variant="success"
                onClick={() => setDispatchConfirmData({ showModal: false, showAcceptModel: true })}
                className="bg-success text-white"
            >
                Accept
            </Button>
            {!Number(dispatchDetails.hideDriverPortalDeclineButton) && (
                <Button
                    variant="danger"
                    disabled={dispatchDetails.driverDeclinedAt}
                    onClick={() => setDispatchConfirmData({ showModal: true, showAcceptModel: false })}
                    className="bg-danger text-white"
                >
                    Decline
                </Button>
            )}
        </ButtonGroup>
    );

    const checkInOutRenderer = (item) => {
        if (!item.showDriverPortalCheckInButton) return;
        else if (item.driverCheckIn && !item.useDriverCheckoutTime) return;
        else if (item.driverCheckIn && item.driverCheckOut && item.useDriverCheckoutTime) return;
        else if (item.driverCheckIn && !item.driverCheckOut && item.useDriverCheckoutTime) {
            return (
                <Button variant="info" onClick={() => handleCheck(item, 'checkout')} disabled={isButtonDisabled}>
                    CHECK OUT
                </Button>
            );
        } else {
            return (
                <Button variant="info" disabled={isButtonDisabled} onClick={() => handleCheck(item, 'checkin')}>
                    CHECK IN
                </Button>
            );
        }
    };

    const getRecordSheet = async () => {
        window.open('/trip_records.pdf');
    };

    return (
        <DetailsPageLayout
            icon="fa-paper-plane"
            title="Assignments"
            textColor={tripSettings?.textColor}
            headerActions={
                <Button variant={'primary'} as={Link} to={headingBackButtonObj.url}>
                    <i className="fas fa-chevron-left" /> {headingBackButtonObj.title}
                </Button>
            }
        >
            <Card>
                <Card.Header
                    className="d-flex justify-content-between flex-column flex-sm-row"
                    style={{ backgroundColor: `${tripSettings?.mainColor}`, gap: '4px' }}
                >
                    <div>
                        <h3 className="text-white m-0 w-auto w-sm-50">Assignment Information</h3>
                    </div>
                    <div className="d-flex justify-content-between" style={{ gap: '4px' }}>
                        {!dispatchDetails.driverAcceptedAt ? dispatchConfirmActions : null}
                        {checkInOutRenderer(dispatchDetails)}
                        {dispatchDetails.tourContractID && (
                            <Button
                                variant="info"
                                onClick={() => {
                                    props.history.push(`/driver/assignments/${dispatchDetails.id}/passengers`);
                                }}
                            >
                                VIEW PASSENGERS
                            </Button>
                        )}
                        <Button variant="info" onClick={handlePrint}>
                            VIEW WORK TICKET
                        </Button>
                        <Button variant="info" onClick={() => getRecordSheet()}>
                            PRINT TRIP RECORDS SHEET
                        </Button>
                    </div>
                </Card.Header>
                <Card.Body className="light-gray-bg p-3">
                    {/*Contact Information*/}
                    <CustomAccordion
                        id={1}
                        title="Basic Information"
                        collapseItem={collapseItem}
                        sectionColor={tripSettings?.sectionColor}
                        handleItemCollapse={handleItemCollapse}
                    >
                        <Row className="mb-2">
                            {errorContent}

                            {/*Vehicle Type Ordered*/}
                            <Col xs={12} md={6} lg={4}>
                                <FormGroup>
                                    <Form.Label>Vehicle Type Ordered</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="vehicleDisplayName"
                                        value={dispatchDetails.vehicleDisplayName}
                                        disabled
                                    />
                                </FormGroup>
                            </Col>
                            {/*Assigned Vehicle*/}
                            <Col xs={12} md={6} lg={4}>
                                <FormGroup>
                                    <Form.Label>Assigned Vehicle</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="vehicleDisplayName"
                                        value={dispatchDetails.assignedVehicleName}
                                        disabled
                                    />
                                </FormGroup>
                            </Col>
                            {dispatchDetails.displayDispatchPriceToDriver ? (
                                <Col xs={12} md={6} lg={4}>
                                    <FormGroup>
                                        <Form.Label>Dispatch Price</Form.Label>
                                        <Form.Control
                                            type="text"
                                            id="vehiclePrice"
                                            name="vehiclePrice"
                                            placeholder="Additional route details"
                                            {...register('vehiclePrice')}
                                            readOnly={true}
                                        />
                                    </FormGroup>
                                </Col>
                            ) : (
                                ''
                            )}
                        </Row>
                    </CustomAccordion>

                    {/*Segments*/}
                    <CustomAccordion
                        id={3}
                        collapseItem={collapseItem}
                        handleItemCollapse={handleItemCollapse}
                        sectionColor={tripSettings?.sectionColor}
                        title={dispatchDetails.routeDescription || 'TRIP DETAILS'}
                    >
                        <SimpleTable
                            fieldNames={[
                                ['startAddress', 'Start Address'],
                                ['destinationAddress', 'Destination Address'],
                                ['departureTime', 'Departure Time'],
                                ['arrivalTime', 'Arrival Time'],
                                tripSettings.useMetric ? ['kilometers', 'km'] : ['miles', 'Miles'],
                            ]}
                            data={segments}
                        />
                        <div className={'mx-2 mt-2'}>
                            <Row className="mb-2">
                                {/*Driver Start Time*/}
                                <Col xs={12} md={4} className="departure">
                                    <FormGroup>
                                        <Form.Label>Driver Start Time</Form.Label>
                                        <Controller
                                            render={(props) => (
                                                <label className="test">
                                                    <DateTimePicker
                                                        date={
                                                            dispatchDetails.driverStartTime
                                                                ? dispatchDetails.driverStartTime
                                                                : undefined
                                                        }
                                                        defaultDate={
                                                            dispatchDetails.driverStartTime
                                                                ? dispatchDetails.driverStartTime
                                                                : null
                                                        }
                                                        format={'MM/DD/YYYY HH:mm'}
                                                        onChange={(date) => props.onChange(date ? date.date : null)}
                                                        readOnly={true}
                                                    />
                                                </label>
                                            )}
                                            name="driverStartTime"
                                            control={control}
                                        />
                                    </FormGroup>
                                </Col>
                                {/*Driver End Time*/}
                                <Col xs={12} md={4} className="departure">
                                    <FormGroup>
                                        <Form.Label>Driver End Time</Form.Label>
                                        <Controller
                                            render={(props) => (
                                                <label className="test">
                                                    <DateTimePicker
                                                        date={
                                                            dispatchDetails.driverEndTime
                                                                ? dispatchDetails.driverEndTime
                                                                : undefined
                                                        }
                                                        defaultDate={
                                                            dispatchDetails.driverEndTime
                                                                ? dispatchDetails.driverEndTime
                                                                : null
                                                        }
                                                        format={'MM/DD/YYYY HH:mm'}
                                                        onChange={(date) => props.onChange(date ? date.date : null)}
                                                        readOnly={true}
                                                    />
                                                </label>
                                            )}
                                            name="driverEndTime"
                                            control={control}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row className="mb-2">
                                <Col xs={12} md={5} lg={6}>
                                    <FormGroup>
                                        <Form.Label>Pickup Instructions</Form.Label>
                                        <Controller
                                            control={control}
                                            name="pickupInstructions"
                                            id="pickupInstructions"
                                            render={({ onChange }) => {
                                                return (
                                                    <DefaultEditor
                                                        className="css-reset"
                                                        value={dispatchDetails.pickupInstructions}
                                                        onChange={(e) => onChange(e.target.value)}
                                                        disabled={true}
                                                    />
                                                );
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col xs={12} md={5} lg={6}>
                                    <FormGroup>
                                        <Form.Label>Destination Instructions</Form.Label>
                                        <Controller
                                            control={control}
                                            name="destinationInstructions"
                                            id="destinationInstructions"
                                            render={({ onChange }) => {
                                                return (
                                                    <DefaultEditor
                                                        className="css-reset"
                                                        value={dispatchDetails.destinationInstructions}
                                                        onChange={(e) => onChange(e.target.value)}
                                                        disabled={true}
                                                    />
                                                );
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col xs={12} md={5} lg={6}>
                                    <FormGroup>
                                        <Form.Label>Additional Route Details</Form.Label>

                                        <Controller
                                            control={control}
                                            name="additionalRouteDetails"
                                            id="additionalRouteDetails"
                                            render={({ onChange }) => {
                                                return (
                                                    <DefaultEditor
                                                        className="css-reset"
                                                        value={dispatchDetails.additionalRouteDetails}
                                                        onChange={(e) => onChange(e.target.value)}
                                                        disabled={true}
                                                    />
                                                );
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col xs={12} md={5} lg={6}>
                                    <FormGroup>
                                        <Form.Label>Additional Trip Requirements</Form.Label>
                                        <Controller
                                            control={control}
                                            name="additionalTripReq"
                                            id="additionalTripReq"
                                            render={({ onChange }) => {
                                                return (
                                                    <DefaultEditor
                                                        className="css-reset"
                                                        value={dispatchDetails.additionalTripReq}
                                                        onChange={(e) => onChange(e.target.value)}
                                                        disabled={true}
                                                    />
                                                );
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col xs={12} md={5} lg={6}>
                                    <FormGroup>
                                        <Form.Label>Driver Instructions</Form.Label>
                                        <Controller
                                            control={control}
                                            name="driverInstructions"
                                            id="driverInstructions"
                                            render={({ onChange }) => {
                                                return (
                                                    <DefaultEditor
                                                        value={dispatchDetails.driverInstructions}
                                                        onChange={(e) => onChange(e.target.value)}
                                                        disabled={true}
                                                    />
                                                );
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </div>
                    </CustomAccordion>
                    <CustomAccordion
                        id={'otherDriverInfo'}
                        title="OTHER DRIVER INFORMATION"
                        collapseItem={collapseItem}
                        sectionColor={tripSettings?.sectionColor}
                        handleItemCollapse={handleItemCollapse}
                    >
                        <ListingTable
                            data={dispatchDetails.otherDrivers}
                            loading={loading}
                            pageInfo={{
                                first: 0,
                                last: Array.isArray(dispatchDetails.otherDrivers)
                                    ? dispatchDetails.otherDrivers.length
                                    : 0,
                            }}
                            mainColor={tripSettings?.mainColor}
                            defaultSort={{ key: 'createdAt', order: false }}
                            fieldNames={[
                                ['routeDescription', 'Route Description', false],
                                ['driverFullName', 'Driver Name', false],
                                ['driverMobile', 'Mobile Phone #', false],
                                ['vehicleId', 'Vehicle ID', false],
                            ]}
                        />
                    </CustomAccordion>
                </Card.Body>
            </Card>
            {dispatchConfirmData.showModal && (
                <Dialog
                    title={'Confirm Dispatch Decline'}
                    message={'Are you sure you wish to decline this dispatch assignment?'}
                    displayOkButton
                    okButtonText="YES"
                    okButtonClick={() => updateDriverConfirmed(0)}
                    displayCancelButton
                    cancelButtonText="NO"
                    cancelButtonClick={() =>
                        setDispatchConfirmData({
                            showModal: false,
                            showAcceptModel: false,
                        })
                    }
                    onCloseClick={() =>
                        setDispatchConfirmData({
                            showModal: false,
                            showAcceptModel: false,
                        })
                    }
                />
            )}
            {dispatchConfirmData.showAcceptModel && (
                <Dialog
                    title={'Confirm Dispatch Acceptance'}
                    message={'Are you sure you wish to accept this dispatch assignment?'}
                    displayOkButton
                    okButtonText="YES"
                    okButtonClick={() => updateDriverConfirmed(1)}
                    displayCancelButton
                    cancelButtonText="NO"
                    cancelButtonClick={() =>
                        setDispatchConfirmData({
                            showModal: false,
                            showAcceptModel: false,
                        })
                    }
                    onCloseClick={() =>
                        setDispatchConfirmData({
                            showModal: false,
                            showAcceptModel: false,
                        })
                    }
                />
            )}
            {loading && (
                <Container className="mb-5 text-center">
                    <Modal show={true} animation={false} className="loading-modal text-center">
                        <Spinner animation="border" variant="primary" />
                    </Modal>
                </Container>
            )}
        </DetailsPageLayout>
    );
};

export default DispatchDetails;
